import React, { ChangeEvent, useState } from "react";
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";
import { Folder } from "@mui/icons-material";
import { FormField } from "../../types";

interface EditDatasetNameDialogProps {
  showDatasetNameDialog: boolean;
  formData: { datasetName: string };
  setShowDatasetNameDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setFormData: React.Dispatch<React.SetStateAction<Record<FormField, string>>>;
}

const DatasetNameDialog: React.FC<EditDatasetNameDialogProps> = ({
  showDatasetNameDialog,
  formData,
  setShowDatasetNameDialog,
  setFormData,
}) => {
  const [newDatasetName, setNewDatasetName] = useState<string>("");

  const handleSave = () => {
    setFormData((formData) => ({
      ...formData,
      datasetName: newDatasetName,
    }));
    setShowDatasetNameDialog(false);
  };

  const handleClose = () => {
    setShowDatasetNameDialog(false);
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setNewDatasetName(event.target.value);
  };

  return (
    <Dialog open={showDatasetNameDialog} maxWidth="sm">
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={500}
          gap={2}
          textAlign="center"
        >
          <Typography>
            <strong>Change Dataset Name</strong>
          </Typography>
          <TextField
            id="new-dataset-name"
            fullWidth
            placeholder={formData.datasetName || "New dataset name"}
            aria-label="New Dataset Name"
            value={newDatasetName}
            onChange={handleChange}
            inputProps={{ "data-testid": "dataset-name-textfield" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Folder />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box pt={1} display="flex" justifyContent="end" gap={1}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            data-testid="dataset-name-save-button"
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            type="submit"
            variant="text"
            color="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DatasetNameDialog;
