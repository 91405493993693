import React from "react";
import {
  ChangeEvent,
  DragEvent,
  FunctionComponent,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import { makeStyles } from "tss-react/mui";
import { Close, Delete, UploadFile } from "@mui/icons-material";
import { UploadedFile } from "../../types";

const useStyles = makeStyles()((theme) => ({
  dragDropField: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    // justifyContent: "center",
    // padding: "em 0",
    cursor: "pointer",
    borderRadius: "4px",
    fontWeight: "bold",
    minHeight: "150px",
    height: "auto",
    boxSizing: "initial",
  },
  active: {
    border: "1px dashed green",
  },
  inactive: {
    border: "1px dashed var(--divider, rgba(0, 0, 0, 0.12))",
    borderColor: theme.palette.secondary.main,
    "&:hover": {
      borderColor: "var(--primary-selected, rgba(169, 169, 227, 0.8))",
      backgroundColor: "var(--primary-selected, rgba(169, 169, 227, 0.08))",
      "& .icon": {
        color: "var(--primary-selected, rgba(169, 169, 227, 1))",
      },
    },
  },
  list: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  delete: {
    position: "relative",
    width: "1em",
    zIndex: 100,
    color: "red",
  },
  deleteContainer: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    paddingRight: "0.5em",
    // paddingTop: "0.5em"
  },
}));

export interface ZipStatus {
  percentage: number;
  datasetName: string | null;
  inProgress: boolean;
}

interface DragAndDropProps {
  dragAndDropAriaLabel: string;
  uploadedFiles: UploadedFile[];
  helperText: string;
  hasError: boolean;
  handleFileSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDrop: (event: React.DragEvent) => void;
  handleDeleteFile: (fileId: string | undefined) => void;
  zipStats: ZipStatus | null;
  datasetName: string;
  isUploading: boolean;
}

const DragAndDrop: FunctionComponent<DragAndDropProps> = (props) => {
  const { classes } = useStyles();
  const [inDropZone, setInDropZone] = useState<boolean>(false);
  const inputFiles = useRef<HTMLInputElement | null>(null);
  const inputFolder = useRef<HTMLInputElement | null>(null);

  const stopEventPropagation = (
    e: React.MouseEvent<HTMLElement> | DragEvent,
  ) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const deleteFile =
    (fileId: string | undefined) => (e: React.MouseEvent<HTMLElement>) => {
      stopEventPropagation(e);
      props.handleDeleteFile(fileId);
    };

  const resetFileInput = (e?: React.MouseEvent<HTMLElement>) => {
    e && stopEventPropagation(e);
    if (inputFolder.current && inputFiles.current) {
      inputFiles.current.value = "";
      inputFolder.current.value = "";
    }
  };

  const onUploadFilesClick = () => {
    if (inputFiles !== null && inputFiles.current !== null) {
      inputFiles.current.click();
    }
  };

  const onUploadFolderClick = (e: React.MouseEvent<HTMLElement>) => {
    stopEventPropagation(e);
    if (inputFolder !== null && inputFolder.current !== null) {
      inputFolder.current.click();
    }
  };

  const handleDragEnter = (e: DragEvent) => {
    stopEventPropagation(e);
    setInDropZone(true);
  };

  const handleDragLeave = (e: DragEvent) => {
    stopEventPropagation(e);
    setInDropZone(false);
  };

  const handleDragOver = (e: DragEvent) => {
    stopEventPropagation(e);
    setInDropZone(true);
  };

  return (
    <Box width={1}>
      <Box
        width={1}
        role="button"
        tabIndex={0}
        className={`${classes.dragDropField} ${
          inDropZone || props.uploadedFiles.length == 0
            ? classes.active
            : classes.inactive
        }`}
        draggable
        data-testid="dropzone"
        aria-label={props.dragAndDropAriaLabel}
        onClick={() => {
          onUploadFilesClick();
        }}
        onKeyDown={() => {
          onUploadFilesClick();
        }}
        onDrop={(e) => props.handleDrop(e)}
        onDragOver={(e) => handleDragOver(e)}
        onDragEnter={(e) => handleDragEnter(e)}
        onDragLeave={(e) => handleDragLeave(e)}
      >
        {props.uploadedFiles.length > 0 && (
          <div className={classes.deleteContainer}>
            <Tooltip title="Delete all files.">
              <IconButton
                onClick={deleteFile(undefined)}
                aria-label="delete-all-files"
                className={classes.delete}
                disabled={props.isUploading}
              >
                <Close />
              </IconButton>
            </Tooltip>
          </div>
        )}
        {props.zipStats?.inProgress ? (
          <Box display="flex" alignItems="center" gap={1}>
            <CircularProgressWithLabel
              value={props.zipStats.percentage}
              size={42}
              color="secondary"
            />
            <Typography variant="body2" color="gray">
              Compressing {props.zipStats.datasetName || "..."}
            </Typography>
          </Box>
        ) : !props.zipStats?.inProgress && props.uploadedFiles.length > 0 ? (
          <Box display="flex" alignItems="center" flexDirection="column">
            {/* <Typography>
              Dataset name: <strong>{props.datasetName}</strong>
            </Typography> */}
            <Box display="flex" alignItems="center" paddingBottom="1em">
              <Typography variant="body2" color="gray">
                {props.uploadedFiles.map((file) => (
                  <li key={file.id} className={classes.list}>
                    {file.file.name}
                    <IconButton
                      id={file.id + "-delete-buttton"}
                      onClick={deleteFile(file.id)}
                      disabled={props.isUploading}
                    >
                      <Delete id={file.id + "-delete-icon"} />
                    </IconButton>
                  </li>
                ))}
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              paddingTop="1em"
              paddingBottom="1em"
              gap={1}
            >
              <UploadFile fontSize="large" color="secondary" className="icon" />
              <Box display="flex" alignItems="center">
                <Typography
                  variant="body2"
                  color="secondary.main"
                  fontWeight="bold"
                  fontSize={18}
                >
                  Click To upload &nbsp;
                </Typography>
                <Typography variant="body2" color="gray">
                  or drag and drop
                </Typography>
              </Box>
              <Typography variant="body2" color="gray">
                files or folder with files (JPEG)
              </Typography>
              <Button
                variant="text"
                color="secondary"
                onClick={onUploadFolderClick}
                size="small"
              >
                <Typography
                  variant="body2"
                  color="secondary.main"
                  fontWeight="bold"
                  textTransform="initial"
                >
                  Click here to select a folder to upload
                </Typography>
              </Button>
            </Box>
          </>
        )}
      </Box>
      <input
        id="files"
        type="file"
        name="files"
        data-testid="file-input"
        ref={inputFiles}
        accept=".JPG,.JPEG"
        style={{ display: "none" }}
        onChange={props.handleFileSelect}
        multiple
      />
      <input
        id="folder"
        type="file"
        name="folder"
        data-testid="folder-input"
        // @ts-expect-error needs following property to work
        // eslint-disable-next-line react/no-unknown-property
        directory=""
        webkitdirectory=""
        // eslint-disable-next-line react/no-unknown-property
        mozdirectory=""
        ref={inputFolder}
        style={{ display: "none" }}
        onChange={props.handleFileSelect}
      />

      {props.hasError && (
        <FormHelperText data-testid="file-error-label" error={props.hasError}>
          {props.helperText}
        </FormHelperText>
      )}
    </Box>
  );
};
export default DragAndDrop;
