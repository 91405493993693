import React from "react";
import { Dialog, DialogContent, Box, Typography, Button } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

interface UploadSuccessDialogProps {
  uploadSuccess: boolean;
  processIntermediateName: string;
  setUploadSuccess: (value: boolean) => void;
}

const UploadSuccessDialog: React.FC<UploadSuccessDialogProps> = ({
  uploadSuccess,
  processIntermediateName,
  setUploadSuccess,
}) => {
  const handleClose = () => {
    setUploadSuccess(false);
  };

  return (
    <Dialog open={uploadSuccess} maxWidth="sm">
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={300}
          gap={2}
          textAlign="center"
        >
          <CheckCircle sx={{ fontSize: 52 }} color="success" />
          <Typography>
            Your Dataset {processIntermediateName} was successfully uploaded to
            the iPEN Media Data Platform
          </Typography>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UploadSuccessDialog;
