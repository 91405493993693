import React, { FC, useEffect, useState } from "react";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { Cancel, CheckCircle, CloudSync } from "@mui/icons-material";
import { searchDateFormat, string2FullDate } from "../util/date";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import { Dataset, DatasetState } from "../openapi/api";
import { useApi } from "../hooks/useApi";

// extend dayjs
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

type DateRange<T> = [T | null, T | null];
interface DatasetInfoProps {}

const SidebarWidth = "400px";

const levelIcons: Record<DatasetState, JSX.Element> = {
  [DatasetState.New]: <CloudSync />,
  [DatasetState.Processing]: <CloudSync />,
  [DatasetState.Finished]: <CloudSync />,
  [DatasetState.Finishing]: <CloudSync />,
  [DatasetState.Failed]: <Cancel />,
};

export const DatasetInfo: FC<DatasetInfoProps> = () => {
  const { findDatasets } = useApi();
  const [loading, setLoading] = useState<boolean>(false);
  const [filteredDataset, setFilteredDataset] = useState<Dataset[]>([]);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs().subtract(1, "week"),
    dayjs(),
  ]);
  const [selectedDatasetState, setSelectedDatasetState] = useState<
    DatasetState | undefined
  >(undefined);

  const toggleDatasetState = (datasetState: DatasetState) => {
    if (datasetState == selectedDatasetState) {
      setSelectedDatasetState(undefined);
    } else {
      setSelectedDatasetState(datasetState);
    }
  };

  useEffect(() => {
    let earliestDate = dayjs().subtract(1, "week");
    let latestDate = dayjs();

    if (dateRange[0] !== null) {
      earliestDate = dateRange[0];
    }
    if (dateRange[1] !== null) {
      latestDate = dateRange[1];
    }

    setLoading(true);

    findDatasets(
      "name",
      selectedDatasetState,
      latestDate.format(searchDateFormat),
      earliestDate.format(searchDateFormat),
    ).then(async (response) => {
      setFilteredDataset(response);
      setLoading(false);
    });
  }, [dateRange, selectedDatasetState]);

  return (
    <Box height={1} display="flex" width={1}>
      <Box
        maxWidth={SidebarWidth}
        flexShrink={0}
        borderRight={1}
        height={1}
        borderColor="#dddddd"
        boxShadow="1px 0 9px 1px #c4c4c4"
      >
        <Box p={2}>
          <Typography variant="h1" fontSize={24}>
            Last Uploaded Dataset
          </Typography>
        </Box>
        <Divider sx={{ opacity: 0.1 }} variant="fullWidth" />
        <Box display="flex" flexDirection="column" px={2} mt={2} gap={2}>
          <Typography fontWeight={700}>Select Dates</Typography>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: "Check-in", end: "Check-out" }}
          >
            <DatePicker
              label="Start date"
              format="YYYY/MM/DD"
              value={dateRange[0]}
              onChange={(newValue) => {
                setDateRange((range) => [newValue, range[1]]);
              }}
            />
            <DatePicker
              label="End date"
              format="YYYY/MM/DD"
              value={dateRange[1]}
              onChange={(newValue) => {
                setDateRange((range) => [range[0], newValue]);
              }}
            />
          </LocalizationProvider>
        </Box>

        <Box px={2} mt={2} gap={2} display="flex" flexDirection="column">
          <Typography fontWeight={700}>Processing Level</Typography>
          <Box display="flex" gap={1} flexWrap="wrap">
            {Object.values(DatasetState).map((level: DatasetState) => {
              return (
                <Chip
                  icon={level in levelIcons ? levelIcons[level] : <></>}
                  key={level}
                  label={level}
                  onClick={() => toggleDatasetState(level)}
                  color={selectedDatasetState == level ? "error" : "secondary"}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box width={1} display="flex" flexDirection="column">
        <Box p={2}>
          <Typography variant="h1" fontSize={24}>
            Results {!loading && <>({filteredDataset.length})</>}
          </Typography>
        </Box>
        <Divider sx={{ opacity: 0.1 }} variant="fullWidth" />
        {loading && (
          <Box
            display="flex"
            justifyContent="center"
            height={100}
            alignItems="center"
          >
            <CircularProgress size={24} />
          </Box>
        )}
        {!loading && filteredDataset.length === 0 && (
          <Typography color="error" p={2}>
            Selected criteria has no results.
          </Typography>
        )}
        <Grid
          container
          width={1}
          gap={2}
          p={2}
          gridTemplateColumns="repeat(3, minmax(0, 1fr))"
          display="grid"
          style={{
            overflowX: "scroll",
          }}
          alignItems="flex-start"
        >
          {filteredDataset.map((dataset, index) => {
            return (
              <Grid
                item
                key={index}
                p={1}
                width={1}
                px={2}
                border={1}
                borderColor="#dddddd"
                boxShadow="1px 0px 6px 0px #e9e9e9"
                borderRadius={1}
                height={1}
                sx={{
                  ":hover": {
                    borderColor: "#aaa",
                  },
                }}
                display="flex"
                flexDirection="column"
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography
                    mb={1}
                    fontWeight={700}
                    style={{ wordBreak: "break-all" }}
                    mr={1}
                  >
                    {dataset["name"]}
                  </Typography>
                  <Box flexShrink={0}>
                    {DatasetState.Finished == dataset["state"] && (
                      <CheckCircle color="secondary" />
                    )}
                    {dataset["state"] === DatasetState.Failed && (
                      <Tooltip title="Show error" placement="top">
                        <Cancel
                          color="error"
                          sx={{
                            cursor: "pointer",
                          }}
                          // Will be used later
                          // onClick={() => {
                          //   if (dataset["id"]) {
                          //     const datasetId = dataset["id"];
                          //     setToggleError((val) =>
                          //       val.includes(datasetId)
                          //         ? val.filter((id) => id !== datasetId)
                          //         : val.concat(datasetId),
                          //     );
                          //   }
                          // }}
                        />
                      </Tooltip>
                    )}
                    {dataset["state"] == DatasetState.New ||
                      dataset["state"] == DatasetState.Finished ||
                      (dataset["state"] == DatasetState.Processing && (
                        <CloudSync color="secondary" />
                      ))}
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" height={1}>
                  <Typography variant="caption">
                    Created : {string2FullDate(dataset["created_at"])}
                  </Typography>
                  <Typography variant="caption" fontSize={14}>
                    Id : {dataset["id"]}
                  </Typography>
                  <Typography variant="caption" fontSize={14}>
                    {/* Solution : {dataset?.relatedSolution?.name} */}
                  </Typography>
                  {/* 
                  
                  Will be used later 

                  {toggledError.includes(dataset['id'] ? dataset['id'] : "") &&
                    dataset?.errorMessages &&
                    dataset?.errorMessages.length > 0 &&
                    dataset.errorMessages.map((error) => (
                      <Box
                        display="flex"
                        height={1}
                        mt={1}
                        gap={1}
                        flexDirection="column"
                        key={error}
                      >
                        <Divider sx={{ opacity: 0.1 }} variant="fullWidth" />
                        <Typography
                          variant="caption"
                          fontSize={14}
                          color="error"
                          lineHeight="normal"
                        >
                          {error}
                        </Typography>
                      </Box>
                    ))} */}
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};
